import { useState, FC } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ILoginForm } from 'types/authentication/loginForm'
import { LoginMessages } from 'pages/Login/Login.utils'
import { useAuthorizeTokenMutation } from 'services/api/endpoints/auth'
import { CommonErrorMessages } from 'helpers/custom/commonMessages'
import getErrorMessage from 'helpers/custom/functions/getErrorMessage'
import { useAuth } from 'hooks/custom/useAuth'
import IconAlert from 'components/common/IconAlert'
import { menuRoutes } from 'routes/routes'

const LoginForm: FC<ILoginForm> = function LoginForm({ hasLabel, layout }) {
  const [_, { login }] = useAuth()
  const [submitLogin, { isLoading }] = useAuthorizeTokenMutation()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const response = await submitLogin({
      managerAuthorizeRequest: {
        Username: formData.email,
        Password: formData.password,
      },
    })

    if ('error' in response) {
      setErrorMessage(getErrorMessage(response.error, CommonErrorMessages.DefaultError))
      return
    }

    if (response.data.AuthToken) {
      login(response.data.AuthToken)
      navigate(menuRoutes.dashboard.to)
      return
    }

    setErrorMessage(CommonErrorMessages.DefaultError)
  }

  const handleFieldChange = (e: any): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{LoginMessages.UsernamePlaceholder}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? LoginMessages.UsernamePlaceholder : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{LoginMessages.PasswordPlaceholder}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? LoginMessages.PasswordPlaceholder : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-end align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/">
            {LoginMessages.ForgotPasswordLabel}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || isLoading}
        >
          {isLoading ? LoginMessages.LoggingIn : LoginMessages.SubmitButtonLabel}
        </Button>
      </Form.Group>

      {errorMessage && (
        <IconAlert
          className="mt-3"
          variant="danger"
          dismissible
          onClose={() => setErrorMessage('')}
        >
          <p className="mb-0">{errorMessage}</p>
        </IconAlert>
      )}
    </Form>
  )
}

export default LoginForm
