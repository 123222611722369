import { Button, Modal } from 'react-bootstrap'
import { ConfirmationModalMessages } from './ConfirmationModal.utils'

export interface ConfirmationModalProps {
  description: React.ReactNode
  isVisible: boolean
  submitLabel?: string
  cancelLabel?: string
  onSubmit: () => void
  onClose: () => void
}

export default function ConfirmationModal({
  description,
  isVisible,
  onClose,
  onSubmit,
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
}: ConfirmationModalProps) {
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      size="sm"
      aria-labelledby={ConfirmationModalMessages.ArialLabelledBy}
      centered
      data-testid={ConfirmationModalMessages.ModalTestId}
    >
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} variant="danger">
          {submitLabel}
        </Button>
        <Button onClick={onClose} variant="falcon-default">
          {cancelLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
