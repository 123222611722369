import { APIError } from 'services/api/api.utils'
import { CommonErrorMessages } from '../commonMessages'

const getErrorMessage = (
  error: APIError,
  defaultErrorMessage = CommonErrorMessages.DefaultError as string
) => {
  if (!error) {
    return defaultErrorMessage
  }

  if ('message' in error) {
    return error.message || defaultErrorMessage
  }

  if ('data' in error) {
    if (typeof error.data === 'string') {
      return defaultErrorMessage
    }

    if (typeof error.data !== 'string') {
      const errorWithModelObject = Object.values(error.data.ModelState || {})

      if ('Message' in error.data && errorWithModelObject.length === 0) {
        return error.data.Message || defaultErrorMessage
      }

      return errorWithModelObject[0][0]
    }
  }

  return defaultErrorMessage
}

export default getErrorMessage
