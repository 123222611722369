import React, { FC, useContext } from 'react'
import { Button, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap'
import AppContext from 'context/Context'

const renderTooltip: FC<TooltipProps & React.RefAttributes<HTMLDivElement>> = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Toggle Navigation
  </Tooltip>
)

const ToggleButton: FC = function ToggleButton() {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig,
  } = useContext(AppContext)

  const handleClick = (): void => {
    document.getElementsByTagName('html')[0].classList.toggle('navbar-vertical-collapsed')
    setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
  }

  return (
    <OverlayTrigger trigger="hover" placement="auto" overlay={renderTooltip}>
      <div className="toggle-icon-wrapper">
        <Button
          variant="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={handleClick}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </OverlayTrigger>
  )
}

export default ToggleButton
