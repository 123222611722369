import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { version } from 'config'

const Footer: FC = function Footer() {
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--2 mt-1 mb-4">
        <Col sm="auto"></Col>
        <Col sm="auto">
          <p className="mb-0 text-600">Falcon v{version}</p>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
