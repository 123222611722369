import { FC } from 'react'
import { Col, Card, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { IFalconCardHeader, ITitle } from 'types'

const Title: FC<ITitle> = function Title({
  titleTag: TitleTag = 'h5',
  className,
  breakPoint,
  children,
}) {
  return (
    <TitleTag
      className={classNames(
        {
          'mb-0': !breakPoint,
          [`mb-${breakPoint}-0`]: !!breakPoint,
        },
        className
      )}
    >
      {children}
    </TitleTag>
  )
}
const FalconCardHeader: FC<IFalconCardHeader> = function FalconCardHeader({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  endEl,
  children,
}) {
  return (
    <Card.Header className={classNames(className, { 'bg-light': light })}>
      {endEl ? (
        <Row className="align-items-center">
          <Col>
            <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
              {title}
            </Title>
            {children}
          </Col>
          <Col
            {...{ [breakPoint || 'xs']: 'auto' }}
            className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
          >
            {endEl}
          </Col>
        </Row>
      ) : (
        <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
          {title}
        </Title>
      )}
    </Card.Header>
  )
}

export default FalconCardHeader
