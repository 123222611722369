import { FC, useContext } from 'react'
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import ProfileDropdown from 'components/navbar/top/ProfileDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from 'context/Context'
import NineDotMenu from './NineDotMenu'

const TopNavRightSideNavItem: FC = function TopNavRightSideNavItem() {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext)
  return (
    <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">
      <Nav.Item as="li">
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={(): void => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{isDark ? 'Switch to light theme' : 'Switch to dark theme'}</Tooltip>}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="fs-0" />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <NineDotMenu />
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem
