import Flex from 'components/common/Flex'
import { useFormik } from 'formik'
import { UsersFilterStatus, UsersFilterStatusLabel } from 'pages/Users/Users.utils'
import { useMemo } from 'react'
import { Button, Col, Form, Placeholder, Row } from 'react-bootstrap'
import {
  EmployeeRole,
  useEmployeeGetDetailsQuery,
  useEmployeeGetRolesQuery,
} from 'services/api/endpoints/users'
import {
  getUserFormSchema,
  initUserForm,
  UserNotFound,
  UserFormMessages,
  UsersFormProps,
  RoleError,
} from './UserForm.utils'
import format from 'date-fns/format'

export default function UsersForm({ id }: UsersFormProps) {
  const isEditMode = !!id

  const { isLoading: isLoadingRoles, data: roles, isError: rolesError } = useEmployeeGetRolesQuery()
  const {
    isLoading: isLoadingEmployee,
    data: employee,
    isError,
  } = useEmployeeGetDetailsQuery({ id: id! }, { skip: !isEditMode })

  const init = useMemo(() => {
    return {
      firstName: employee?.FirstName ?? initUserForm.firstName,
      lastName: employee?.LastName ?? initUserForm.lastName,
      email: employee?.Email ?? initUserForm.email,
      username: employee?.Username ?? initUserForm.username,
      password: initUserForm.password,
      confirmPassword: initUserForm.confirmPassword,
      roles:
        (employee?.Roles?.map((role: EmployeeRole) => role.Name) as string[]) ?? initUserForm.roles,
      status: employee?.Status ?? initUserForm.status,
    }
  }, [employee])

  const formik = useFormik({
    initialValues: init,
    validationSchema: getUserFormSchema(isEditMode),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      console.log(values)
    },
  })

  const defaultProps = (field: keyof typeof initUserForm) => ({
    value: formik.values[field],
    isInvalid: !!formik.errors[field] && !!formik.touched[field],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  })

  if (isError) {
    return <UserNotFound />
  }

  if (isLoadingEmployee || isLoadingRoles) {
    return (
      <Placeholder as="p" animation="glow">
        <Placeholder style={{ width: '350px' }} />
      </Placeholder>
    )
  }

  if (rolesError) {
    return <RoleError message={UserFormMessages.FailToLoadRolesLabel} />
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      {isEditMode && (
        <Row className="g-3">
          <Form.Check
            type="switch"
            id="status"
            name="status"
            label={String(UsersFilterStatusLabel[formik.values.status as UsersFilterStatus])}
            checked={formik.values.status === UsersFilterStatus.ACTIVE}
            onChange={() =>
              formik.setFieldValue(
                'status',
                formik.values.status === UsersFilterStatus.ACTIVE
                  ? UsersFilterStatus.INACTIVE
                  : UsersFilterStatus.ACTIVE
              )
            }
          />
        </Row>
      )}
      <Row className="mb-3 g-3">
        <Form.Group as={Col} controlId="firstName">
          <Form.Label>{UserFormMessages.FirstNameLabel}</Form.Label>
          <Form.Control type="text" name="firstName" {...defaultProps('firstName')} />
          {formik.touched.firstName && formik.errors.firstName ? (
            <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group as={Col} controlId="lastName">
          <Form.Label>{UserFormMessages.LastNameLabel}</Form.Label>
          <Form.Control type="text" name="lastName" {...defaultProps('lastName')} />
          {formik.touched.lastName && formik.errors.lastName ? (
            <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{UserFormMessages.EmailLabel}</Form.Label>
        <Form.Control type="email" name="email" {...defaultProps('email')} />
        {formik.touched.email && formik.errors.email ? (
          <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="Username">
        <Form.Label>{UserFormMessages.UsernameLabel}</Form.Label>
        <Form.Control name="username" {...defaultProps('username')} />
        {formik.touched.username && formik.errors.username ? (
          <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Row className="mb-3 g-3">
        <Form.Group as={Col} controlId="password">
          <Form.Label>{UserFormMessages.PasswordLabel}</Form.Label>
          <Form.Control type="password" name="password" {...defaultProps('password')} />
          {formik.touched.password && formik.errors.password ? (
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group as={Col} controlId="confirmPassword">
          <Form.Label>{UserFormMessages.ConfirmPasswordLabel}</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            {...defaultProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <Form.Control.Feedback type="invalid">
              {formik.errors.confirmPassword}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Label>{UserFormMessages.RolesLabel}</Form.Label>

        <Flex className="flex-wrap">
          {!roles && isLoadingRoles && (
            <Placeholder as="p" animation="glow">
              <Placeholder style={{ width: '150px' }} />
            </Placeholder>
          )}
          {roles?.map((roleName: string) => (
            <Form.Check
              id={roleName}
              className="py-1 w-25"
              type="checkbox"
              key={roleName}
              label={roleName}
              name="roles"
              value={roleName}
              checked={formik.values.roles.some((role) => role === roleName)}
              onChange={formik.handleChange}
            />
          ))}
        </Flex>

        {formik.errors.roles && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {formik.errors.roles}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Flex className="justify-content-between align-items-center">
        <Flex className="flex-row">
          {isEditMode && (
            <>
              <p className="fs--1 me-4 mb-0 text-500">
                <span className="fw-bold">{UserFormMessages.CreatedDateLabel}: </span>
                {employee?.CreatedDate
                  ? format(new Date(employee?.CreatedDate), 'MM/dd/yyyy HH:mm')
                  : ''}
              </p>
              <p className="fs--1 mb-0 text-500">
                <span className="fw-bold">{UserFormMessages.LastSigninDateLabel}: </span>
                {employee?.LastSigninDate
                  ? format(new Date(employee?.LastSigninDate), 'MM/dd/yyyy HH:mm')
                  : ''}
              </p>
            </>
          )}
        </Flex>
        <Button variant="primary" type="submit" disabled={!formik.isValid}>
          {UserFormMessages.SaveUserLabel}
        </Button>
      </Flex>
    </Form>
  )
}
