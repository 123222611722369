import { FC, useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppContext } from 'context/Context'
import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'
import SimpleLogin from 'components/authentication/simple/Login'
import Dashboard from 'components/dashboards/default'
import ErrorLayout from './ErrorLayout'
import MainLayout from './MainLayout'
import AuthSimpleLayout from './AuthSimpleLayout'
import { appRoutes } from 'routes/routes'
import Users from 'pages/Users/Users'
import Members from 'pages/Members/Members'
import UsersAdd from 'pages/Users/Add/Add'
import UsersEdit from 'pages/Users/Edit/Edit'

const is = require('is_js')

const Layout: FC = function Layout() {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  useContext(AppContext)

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      <Route element={<AuthSimpleLayout />}>
        <Route path={appRoutes.login.main} element={<SimpleLogin />} />
      </Route>

      {/* Protected routes  */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path={appRoutes.users.main} element={<Users />} />
        <Route path={appRoutes.users.add} element={<UsersAdd />} />
        <Route path={appRoutes.users.edit} element={<UsersEdit />} />

        <Route path={appRoutes.members.main} element={<Members />} />
        <Route path={appRoutes.members.add} element={<Members />} />
      </Route>

      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  )
}

export default Layout
