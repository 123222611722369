import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CloseButton } from 'react-bootstrap'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface StringByString {
  [key: string]: string | undefined
}

interface IconsProps {
  variant: string
  dismissible: any
  children: JSX.Element | JSX.Element[]
  className: any
  onClose: any
}
const IconAlert: FC<IconsProps> = function IconAlert({
  variant,
  dismissible,
  children,
  className,
  onClose,
  ...rest
}) {
  const icon: StringByString = {
    success: 'check-circle',
    info: 'info-circle',
    warning: 'exclamation-circle',
    danger: 'times-circle',
  }
  const value = icon[variant]
  return (
    <Alert
      variant={variant}
      className={classNames(className, 'd-flex align-items-center')}
      {...rest}
    >
      <div className={`bg-${variant} me-3 icon-item`}>
        <FontAwesomeIcon icon={value as IconProp} className="text-white fs-3" />
      </div>
      <div className="flex-1">{children}</div>
      {dismissible && <CloseButton onClick={onClose} />}
    </Alert>
  )
}

export default IconAlert
