import { FC } from 'react'
import FalconCardFooterLink from 'components/common/FalconCardFooterLink'
import FalconCardHeader from 'components/common/FalconCardHeader'
import CardDropdown from 'components/common/CardDropdown'
import { Card } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import { Link } from 'react-router-dom'
import Avatar from 'components/common/Avatar'
import classNames from 'classnames'
import { IActiveUser, IActiveUsers } from 'types/dashboard/activeUser'

const ActiveUsers: FC<IActiveUsers> = function ActiveUsers({ users, end = 5, ...rest }) {
  return (
    <Card {...rest}>
      <FalconCardHeader
        light
        title="Active Users"
        titleTag="h6"
        className="py-2"
        endEl={
          <CardDropdown btnRevealClass="" drop={undefined} icon="">
            <div />
          </CardDropdown>
        }
      />
      <Card.Body className="py-2">
        {users.slice(0, end).map(({ id, ...rest }, index) => (
          <ActiveUser id={undefined} {...rest} key={id} isLast={index === users.length - 1} />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="All active users"
        to="/social/followers"
        bg=""
        borderTop={false}
        className=""
      />
    </Card>
  )
}

const ActiveUser: FC<IActiveUser> = function ActiveUser({ name, avatar, role, isLast }) {
  return (
    <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
      <Avatar size={undefined} {...avatar} className={`status-${avatar}`} />
      <div className="ms-3">
        <h6 className="mb-0 fw-semi-bold">
          <Link className="text-900" to="/user/profile">
            {name}
          </Link>
        </h6>
        <p className="text-500 fs--2 mb-0">{role}</p>
      </div>
    </Flex>
  )
}

export default ActiveUsers
