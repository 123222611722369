import { authTokenKey } from '../tokens'

const setAuthorizationHeader = (): Record<string, string> => {
  const token = localStorage.getItem(authTokenKey)
  if (token) {
    return { Authorization: `Bearer ${token}` }
  }
  return {}
}

export default setAuthorizationHeader
