/* eslint-disable react/jsx-key */
import SearchBar from 'components/custom/Table/SearchBar/SearchBar'
import CustomTable from 'components/custom/Table/Table'
import { useCallback, useReducer, useEffect, useMemo } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import {
  initialMembersState,
  PAGE_SIZE,
  tableColumns,
  MembersMessages,
  membersReducer,
} from './Members.utils'
import debounce from 'lodash/debounce'
import { useMemberSearchMembersQuery } from 'services/api/endpoints/members'

export default function Members() {
  const [filterState, membersDispatch] = useReducer(membersReducer, initialMembersState)

  const { data, isFetching } = useMemberSearchMembersQuery(
    { searchTerm: filterState.searchTerm, pageSize: PAGE_SIZE, pageNumber: filterState.pageNumber },
    { skip: !filterState.searchTerm }
  )

  const tableData = useMemo(() => data?.Members ?? [], [data])
  const columns = useMemo(() => tableColumns, [])

  const handleChange = (value: string) => {
    membersDispatch({ type: 'setSearchTerm', payload: value })
  }
  const debouncedSearch = useCallback(debounce(handleChange, 500), [])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [])

  return (
    <Card className="mb-3">
      <Card.Body className="p-0">
        <Container className="gx-0 p-3">
          <Row className="d-flex justify-content-between">
            <Col xs={4} sm={2} className="d-flex align-items-center pe-0">
              <h2 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{MembersMessages.PageTitle}</h2>
            </Col>
            <Col>
              <Row className="d-flex justify-content-end g-2">
                <Col xs={5}>
                  <SearchBar search={filterState.searchTerm} setSearch={debouncedSearch} />
                </Col>
                <Col xs={4}>
                  <Form.Select aria-label={MembersMessages.SelectAriaLabel} size="sm">
                    <option value="1">{MembersMessages.AllTypesLabel}</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <CustomTable
          isLoading={isFetching}
          data={tableData}
          columns={columns}
          paginationOptions={{
            onNextPage: () => membersDispatch({ type: 'nextPage' }),
            onPreviousPage: () => membersDispatch({ type: 'previousPage' }),
            pageIndex: filterState.pageNumber,
            numberOfPages: data?.NumberOfPages ?? 0,
          }}
        />
      </Card.Body>
    </Card>
  )
}
