import { FC } from 'react'
import { isIterableArray } from 'helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { IAvatar, IAvatarGroup } from 'types'
import Flex from './Flex'

const Avatar: FC<IAvatar> = function Avatar({
  size = 'xl',
  rounded = 'circle',
  src,
  name,
  emoji = '😊',
  className,
  mediaClass,
  isExact = false,
  icon,
}) {
  const cssClass = ['avatar', `avatar-${size}`, className].join(' ')
  const mediaClasses = [rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ')

  const getAvatar: () => JSX.Element = function getAvatar() {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={src[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={src[1]} alt="" className="h-50 border-bottom" />
              <img src={src[2]} alt="" className="h-50" />
            </div>
          </div>
        )
      }
      return <img className={mediaClasses} src={src as string} alt="" />
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g)!.join('')}</span>
        </div>
      )
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      )
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    )
  }

  return <div className={cssClass}>{getAvatar()}</div>
}

export const AvatarGroup: FC<IAvatarGroup> = function AvatarGroup({ children, dense, className }) {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense,
      })}
    >
      {children}
    </div>
  )
}

export default Avatar
