import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { SearchBarMessages } from './SearchBar.utils'

interface SearchBarProps {
  search: string
  setSearch: (value: string) => void
}

export default function SearchBar({ search, setSearch }: SearchBarProps) {
  return (
    <InputGroup className={classNames('position-relative')} size="sm">
      <FormControl
        onChange={({ target: { value } }) => {
          setSearch(value)
        }}
        id="search"
        placeholder={SearchBarMessages.Placeholder}
        type="search"
        className="shadow-none"
      />
      <Button size="sm" variant="outline-secondary" className="border-300 hover-border-secondary">
        <FontAwesomeIcon icon="search" className="fs--1" />
      </Button>
    </InputGroup>
  )
}
