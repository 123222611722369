import { FC } from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import { ISection } from 'types'
import Background from './Background'

const Section: FC<ISection> = function Section({
  fluid = false,
  bg,
  image,
  overlay,
  position,
  video,
  bgClassName,
  className,
  children,
  ...rest
}) {
  const bgProps = { image, overlay, position, video, className }
  bgClassName && (bgProps.className = bgClassName)

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && <Background {...bgProps} />}
      <Container fluid={fluid}>{children}</Container>
    </section>
  )
}

export default Section
