import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Placeholder } from 'react-bootstrap'
import { HeaderGroup } from 'react-table'
import { v4 as uuidv4 } from 'uuid'

export function renderSort(column: any) {
  if (column.disableSortBy) {
    return null
  }

  return (
    <>
      {column.isSorted ? (
        column.isSortedDesc ? (
          <span className="ms-1 opacity-50">
            <FontAwesomeIcon icon={faSortDown as IconProp} size="sm" />
          </span>
        ) : (
          <span className="ms-1 opacity-50">
            <FontAwesomeIcon icon={faSortUp as IconProp} size="sm" />
          </span>
        )
      ) : (
        <span className="ms-1 opacity-50">
          <FontAwesomeIcon icon={faSort as IconProp} size="sm" />
        </span>
      )}
    </>
  )
}

export function LoadingPlaceholder({
  headerGroup,
  n = 2,
}: {
  headerGroup: HeaderGroup
  n?: number
}) {
  return (
    <>
      {Array(n)
        .fill(0)
        .map((i) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
              {headerGroup.headers.map((column) => {
                return (
                  <td key={column.id}>
                    <div role="progressbar">
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} size="lg" />
                      </Placeholder>
                    </div>
                  </td>
                )
              })}
            </tr>
          )
        })}
    </>
  )
}
