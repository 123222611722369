import { FC, useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppContext } from 'context/Context'
import classNames from 'classnames'
import Footer from 'components/footer/Footer'
import NavbarTop from 'components/navbar/top/NavbarTop'
import NavbarVertical from 'components/navbar/vertical/NavbarVertical'
import { useAuth } from 'hooks/custom/useAuth'
import { getTokenExpiryTime } from 'helpers/utils'

const MainLayout: FC = function MainLayout() {
  const location = useLocation()
  const { hash, pathname } = location

  const isKanban = pathname.includes('kanban')

  const [authState, actions] = useAuth()

  useEffect(() => {
    if (!authState.isAuth) {
      actions.logout()
    }
  }, [authState.isAuth])

  useEffect(() => {
    if (authState.token) {
      const expiryTime = getTokenExpiryTime(authState.token)
      const now = new Date().getTime()
      const remainingTime = expiryTime - now

      if (expiryTime <= now) {
        return actions.logout()
      }

      const timer = setTimeout(() => {
        actions.logout()
      }, remainingTime)
      return () => clearTimeout(timer)
    }
  }, [authState.token])

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (authState.isAuth) {
    return (
      <div className={isFluid ? 'container-fluid' : 'container'}>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && <NavbarVertical />}
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/* ------ Main Routes ------*/}
          <Outlet />
          {!isKanban && <Footer />}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default MainLayout
