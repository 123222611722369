import { FC } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import team3 from 'assets/img/team/3.jpg'
import Avatar from 'components/common/Avatar'
import { useAuth } from 'hooks/custom/useAuth'
import { appRoutes } from 'routes/routes'

const ProfileDropdown: FC = function ProfileDropdown() {
  const [, actions] = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    actions.logout()
    navigate(appRoutes.login.main)
  }

  return (
    <Dropdown navbar as="li">
      <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="pe-0 ps-2 nav-link">
        <Avatar src={team3} size={undefined} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
