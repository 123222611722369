import { decodeJwt } from 'jose'

const isTokenValid = (token: string | undefined) => {
  if (!token) {
    return false
  }

  const decodedToken = decodeJwt(token || '')
  const currentTime = Date.now()
  const expirationTime = new Date((decodedToken.exp || 0) * 1000).getTime()

  return currentTime < expirationTime
}

export default isTokenValid
