import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBan, faEdit, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { ActionsDropdownMessages } from './ActionsDropdown.utils'

export interface ActionsDropdownProps {
  onEdit: () => void
  onDelete: () => void
}

export default function ActionsDropdown({ onEdit, onDelete }: ActionsDropdownProps) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="falcon-default" size="sm">
          <FontAwesomeIcon icon={faEllipsisV as IconProp} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-2">
          <Dropdown.Item onClick={onEdit}>
            <FontAwesomeIcon icon={faEdit as IconProp} /> {ActionsDropdownMessages.EditLabel}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowConfirmation(true)}>
            <FontAwesomeIcon icon={faBan as IconProp} /> {ActionsDropdownMessages.DeleteLabel}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ConfirmationModal
        isVisible={showConfirmation}
        description={ActionsDropdownMessages.DescriptionLabel}
        submitLabel={ActionsDropdownMessages.DeleteLabel}
        onSubmit={onDelete}
        onClose={() => setShowConfirmation(false)}
      />
    </>
  )
}
