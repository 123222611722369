import isTokenValid from 'helpers/custom/functions/isTokenValid'
import { authTokenKey } from 'helpers/custom/tokens'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from 'routes/routes'
import { dupacoManagementApi } from 'services/api'
import { useAppDispatch, useAppSelector } from 'services/store'
import { selectUser, userAuth, UserData } from 'services/store/slices/userSlice'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const authState = useAppSelector(selectUser)
  const navigate = useNavigate()

  const actions = useMemo(
    () => ({
      login: (token: string) => {
        localStorage.setItem(authTokenKey, token)
        dispatch(userAuth({ isAuth: true, token }))
      },
      logout: () => {
        localStorage.removeItem(authTokenKey)
        dispatch(userAuth({ isAuth: false }))
        dispatch(dupacoManagementApi.util.resetApiState())
        navigate(appRoutes.login.main)
      },
    }),
    [dispatch]
  )

  const authToken = localStorage.getItem(authTokenKey)

  if (authToken && !authState.isAuth) {
    if (!isTokenValid(authToken)) {
      actions.logout()
      return [authState, actions] as [UserData, typeof actions]
    }
    dispatch(userAuth({ isAuth: true }))
  }

  return [authState, actions] as [UserData, typeof actions]
}
