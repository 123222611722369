import { PageRoutesType } from '../types'

export const appRoutes = {
  dashboard: {
    main: '/',
  },
  users: {
    main: '/users',
    add: '/users/add',
    edit: '/users/edit/:id',
  },
  members: {
    main: '/members',
    add: '/members/add',
  },
  configuration: {
    main: '/configuration',
  },
  propertyModel: {
    main: '/property-model',
  },
  smsText: {
    main: '/sms-text',
  },
  sendShineMessage: {
    main: '/send-shine-message',
  },
  disclosureUpload: {
    main: '/disclosure-upload',
  },
  login: {
    main: '/login',
  },
}

export const menuRoutes = {
  dashboard: {
    name: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    to: appRoutes.dashboard.main,
    active: true,
  },
  users: {
    name: 'Users',
    icon: 'fa-solid fa-user-tie',
    to: appRoutes.users.main,
    active: true,
  },
  members: {
    name: 'Members',
    icon: 'fa-solid fa-users',
    to: appRoutes.members.main,
    active: true,
  },
  configuration: {
    name: 'Configuration',
    icon: 'fa-solid fa-toolbox',
    to: appRoutes.configuration.main,
    active: true,
  },
  propertyModel: {
    name: 'Property Model',
    icon: 'fa-solid fa-bullseye',
    to: appRoutes.propertyModel.main,
    active: true,
  },
  smsText: {
    name: 'SMS Text',
    icon: 'fa-solid fa-comment-dots',
    to: appRoutes.smsText.main,
    active: true,
  },
  sendShineMessage: {
    name: 'Send Shine Message',
    icon: 'fa-solid fa-envelope',
    to: appRoutes.sendShineMessage.main,
    active: true,
  },
  disclosureUpload: {
    name: 'Disclosure Upload',
    icon: 'fa-solid fa-file-import',
    to: appRoutes.disclosureUpload.main,
    active: true,
  },
}

export const managementRoutes: PageRoutesType = {
  label: 'Management',
  labelDisable: false,
  children: Object.values(menuRoutes),
}

export default [managementRoutes]
