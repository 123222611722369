import React, { FC, ReactNode, useMemo, useReducer } from 'react'
import { getColor, getItemFromStore } from 'helpers/utils'
import useToggleStylesheet from 'hooks/useToggleStyle'
import { ObjectType, settingsType } from 'types'
import configReducer from 'reducers/configReducer'
import { settings } from './config'
import { AppContext } from './context/Context'

const Main: FC<{ children: ReactNode }> = function Main({ children }) {
  const configState: settingsType = {
    isFluid: getItemFromStore('isFluid', settings.isFluid) as boolean,
    isRTL: getItemFromStore('isRTL', settings.isRTL) as boolean,
    isDark: getItemFromStore('isDark', settings.isDark) as boolean,
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition) as string,
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ) as boolean,
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle) as string,
    currency: settings.currency as string,
    showBurgerMenu: settings.showBurgerMenu as boolean,
    showSettingPanel: false,
    navbarCollapsed: false,
  }

  const [config, configDispatch] = useReducer(configReducer, configState)

  const setConfig: any = function setConfig(key: string, value: string | boolean | ObjectType) {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
        ].includes(key),
      },
    })
  }
  const memoConfig = useMemo(
    () => ({ config, setConfig, configDispatch }),
    [config, setConfig, configDispatch]
  )

  const { isLoaded } = useToggleStylesheet(config.isRTL, config.isDark, configDispatch)

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light'),
        }}
      />
    )
  }
  return <AppContext.Provider value={memoConfig}>{children}</AppContext.Provider>
}

export default Main
