import { FC } from 'react'
import { Button, Card, Col, Form, ProgressBar, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Flex from 'components/common/Flex'
import { IBestSellingProduct, IBestSellingProducts } from 'types/dashboard/bestSellingProducts'

const getTotalPrice = (items: { unit: any; price: any }[]) =>
  items
    .map(({ unit, price }) => unit * price)
    .reduce((total, currentValue) => total + currentValue, 0)

const getProductItemCalculatedData = (unit: number, price: number, totalPrice: number) => {
  const productTotalPrice = unit * price
  const percentage = parseInt(((productTotalPrice * 100) / totalPrice).toFixed(0), 10)
  return { productTotalPrice, percentage }
}

const BestSellingProduct: FC<IBestSellingProduct> = function BestSellingProduct({
  product,
  totalPrice,
  isLast,
}) {
  const { img, title, type, unit, price } = product
  const { productTotalPrice, percentage } = getProductItemCalculatedData(unit, price, totalPrice)

  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          <img className="rounded-1 border border-200" src={img} width="60" alt={title} />
          <div className="ms-3">
            <h6 className="mb-1 fw-semi-bold">
              <Link className="text-dark stretched-link" to="#!">
                {title}
              </Link>
            </h6>
            <p className="fw-semi-bold mb-0 text-500">{type}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-end fw-semi-bold">${productTotalPrice}</td>
      <td className="align-middle pe-card">
        <Flex alignItems="center">
          <ProgressBar now={percentage} style={{ width: '80px', height: 5 }} />
          <div className="fw-semi-bold ms-3">{percentage}%</div>
        </Flex>
      </td>
    </tr>
  )
}

const BestSellingProducts: FC<IBestSellingProducts> = function BestSellingProducts({ products }) {
  const totalPrice = getTotalPrice(products)
  const noOfProducts = products.length

  return (
    <Card className="h-lg-100 overflow-hidden">
      <Card.Body className="p-0 ">
        <Table borderless responsive className="mb-0 fs--1">
          <thead className="bg-light">
            <tr className="text-900">
              <th>Best Selling Products</th>
              <th className="text-end">Revenue ( ${totalPrice})</th>
              <th className="pe-card text-end" style={{ width: '8rem' }}>
                Revenue (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product: any, index: number) => (
              <BestSellingProduct
                product={product}
                totalPrice={totalPrice}
                isLast={index === noOfProducts - 1}
                key={product.id}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 7 days</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Link to="#!">
              <Button variant="falcon-default" size="sm">
                View All
              </Button>
            </Link>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default BestSellingProducts
