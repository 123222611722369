import { FC, useContext } from 'react'
import { managementRoutes } from 'routes/routes'
import AppContext from 'context/Context'
import NavbarDropdownApp from './NavbarDropdownApp'
import NavbarDropdown from './NavbarDropdown'

const NavbarTopDropDownMenus: FC = function NavbarTopDropDownMenus() {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext)

  const handleDropdownItemClick = (): void => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed)
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu)
    }
  }
  return (
    <NavbarDropdown title="management">
      <NavbarDropdownApp items={managementRoutes.children} />
    </NavbarDropdown>
  )
}

export default NavbarTopDropDownMenus
