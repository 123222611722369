import React, { FC } from 'react'
import Layout from 'layouts/Layout'
import { BrowserRouter as Router } from 'react-router-dom'

const App: FC = function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  )
}

export default App
