import Main from 'Main'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'helpers/initFA'
import store from 'services/store'
import addUnauthorizedResponseInterceptor from 'services/api/interceptors/unauthorizedResponseInterceptor'
const { dispatch } = store

addUnauthorizedResponseInterceptor(dispatch)

const root = ReactDOM.createRoot(document.getElementById('main') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main>
        <App />
      </Main>
    </Provider>
  </React.StrictMode>
)
reportWebVitals()
