import { authApi as api } from './auth'

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    employeeGetEmployees: build.query<EmployeeGetEmployeesApiResponse, EmployeeGetEmployeesApiArg>({
      query: (queryArg) => ({
        url: `/Manager/v1/Employee/GetEmployees`,
        params: {
          SearchTerm: queryArg.searchTerm,
          Status: queryArg.status,
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
        },
      }),
    }),
    employeeGetDetails: build.query<EmployeeGetDetailsApiResponse, EmployeeGetDetailsApiArg>({
      query: (queryArg) => ({ url: `/Manager/v1/Employee/GetDetails/${queryArg.id}` }),
    }),
    employeeAddNewSupportUser: build.mutation<
      EmployeeAddNewSupportUserApiResponse,
      EmployeeAddNewSupportUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Manager/v1/Employee/AddNewSupportUser`,
        method: 'POST',
        body: queryArg.addEmployeeRequestViewModel,
      }),
    }),
    employeeGetRoles: build.query<EmployeeGetRolesApiResponse, EmployeeGetRolesApiArg>({
      query: () => ({ url: `/Manager/v1/Employee/GetRoles` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as userApi }
export type EmployeeGetEmployeesApiResponse = /** status 200 Status Code 200 - success

             */ SearchEmployeesResponseModel
export type EmployeeGetEmployeesApiArg = {
  searchTerm?: string
  status?: 'Inactive' | 'Active'
  pageNumber?: number
  pageSize?: number
}
export type EmployeeGetDetailsApiResponse = /** status 200 Status Code 200 - success

             */ EmployeeDetailsViewModel
export type EmployeeGetDetailsApiArg = {
  id: string
}
export type EmployeeAddNewSupportUserApiResponse = /** status 200  */ undefined
export type EmployeeAddNewSupportUserApiArg = {
  addEmployeeRequestViewModel: AddEmployeeRequestViewModel
}
export type EmployeeGetRolesApiResponse = /** status 200 List of Manager Roles */ string[]
export type EmployeeGetRolesApiArg = void
export type EmployeeRole = {
  Id?: string
  Name?: string
}
export type EmployeeAccountStatus = 'Inactive' | 'Active'
export type EmployeeViewModel = {
  Id?: string
  Username?: string
  Roles?: EmployeeRole[]
  Name?: string
  CreatedDate: string
  LastAccessedDate?: string
  Status: EmployeeAccountStatus
}
export type PaginatedListOfEmployeeViewModel = EmployeeViewModel[] & {
  TotalCount: number
  PageCount: number
  PageIndex: number
  PageSize: number
  IsPreviousPage: boolean
  IsNextPage: boolean
  IsFirstPage: boolean
  IsLastPage: boolean
}
export type SearchEmployeesResponseModel = {
  Employees?: PaginatedListOfEmployeeViewModel
  NumberOfPages: number
}
export type ValueProviderResult = {
  AttemptedValue?: string
  Culture?: string
  RawValue?: any
}
export type Exception = {
  Message?: string
  InnerException?: Exception
  StackTrace?: string
  Source?: string
}
export type ModelError = {
  Exception?: Exception
  ErrorMessage?: string
}
export type ModelErrorCollection = ModelError[]
export type ModelState = {
  Value?: ValueProviderResult
  Errors?: ModelErrorCollection
}
export type ModelStateDictionary = {
  Count: number
  IsReadOnly: boolean
  IsValid: boolean
  Keys?: string[]
  Values?: ModelState[]
  Item?: ModelState
}
export type EmployeeAccountStatus2 = 0 | 1
export type EmployeeDetailsViewModel = {
  Id?: string
  FirstName?: string
  LastName?: string
  Email?: string
  Username?: string
  Nickname?: string
  Status: EmployeeAccountStatus2
  CreatedDate: string
  LastAccessedDate: string
  LastSigninDate: string
  Roles?: EmployeeRole[]
}
export type AddEmployeeRequestViewModel = {
  FirstName?: string
  LastName?: string
  Email?: string
  Username?: string
}
export const {
  useEmployeeGetEmployeesQuery,
  useEmployeeGetDetailsQuery,
  useEmployeeAddNewSupportUserMutation,
  useEmployeeGetRolesQuery,
} = injectedRtkApi
