import UsersForm from 'components/custom/UserForm/UserForm'
import { Card, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { UserEditMessages } from './Edit.utils'

export default function UsersEdit() {
  const { id } = useParams()
  return (
    <Card className="mb-3">
      <Card.Body className="p-0">
        <Container className="gx-0 p-3">
          <h2 className="fs-1 mb-3 text-nowrap py-2 py-xl-0">{UserEditMessages.PageTitle}</h2>
          <UsersForm id={id} />
        </Container>
      </Card.Body>
    </Card>
  )
}
