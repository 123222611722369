import React, { FC } from 'react'
import { Nav, Row, Col } from 'react-bootstrap'
import { getFlatRoutes } from 'helpers/utils'
import { INavbarDropdownGeneric, RouteType } from 'types'
import NavbarNavLink from './NavbarNavLink'

const NavbarDropdownApp: FC<INavbarDropdownGeneric> = function NavbarDropdownApp({ items }) {
  const routes = getFlatRoutes(items)

  return (
    <Row>
      <Col md={5}>
        <Nav className="flex-column">
          {routes.unTitled.map((route: RouteType) => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Email" title="Email" />
          {routes.email.map((route: RouteType) => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Events" title="Events" />
          {routes.events.map((route: RouteType) => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Social" title="Social" />
          {routes.social.map((route: RouteType) => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
        </Nav>
      </Col>
      <Col md={5}>
        <NavbarNavLink label="E Commerce" title="E Commerce" />
        {routes.eCommerce.map((route: RouteType) => (
          <NavbarNavLink key={route.name} route={route} />
        ))}
      </Col>
    </Row>
  )
}

export default NavbarDropdownApp
