import classNames from 'classnames'
import Flex from 'components/common/Flex'
import { Button, Placeholder } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationMessages } from './Pagination.utils'
export interface TablePaginationProps {
  onPreviousPage: () => void
  onNextPage: () => void
  numberOfPages: number
  pageIndex: number
  isLoading?: boolean
  noDataToDisplay?: boolean
}

export const TablePagination = ({
  onPreviousPage,
  onNextPage,
  numberOfPages,
  pageIndex,
  noDataToDisplay = false,
  isLoading = false,
}: TablePaginationProps) => {
  const canPreviousPage = pageIndex > 0
  const canNextPage = pageIndex + 1 < numberOfPages

  if (noDataToDisplay && !isLoading) {
    return (
      <Flex className="justify-content-center align-items-center mb-3">
        <div className="me-2">
          <FontAwesomeIcon icon="info-circle" className="fs-1" />
        </div>
        <span className="fs-0">{PaginationMessages.NoDataLabel}</span>
      </Flex>
    )
  }

  return (
    <Flex className="justify-content-between align-items-center p-3 w-100">
      {isLoading ? (
        <div className="w-25">
          <Placeholder as="div" animation="glow">
            <Placeholder xs={6} size="xs" />
          </Placeholder>
        </div>
      ) : (
        <p className="mb-0">
          <span className="d-none d-sm-inline-block me-2">
            Page {pageIndex + 1} of {numberOfPages}
          </span>
        </p>
      )}

      {!isLoading && (
        <Flex>
          <Button
            size="sm"
            variant={canPreviousPage ? 'primary' : 'light'}
            onClick={onPreviousPage}
            className={classNames({ disabled: !canPreviousPage })}
          >
            {PaginationMessages.PreviousPageLabel}
          </Button>
          <Button
            size="sm"
            variant={canNextPage ? 'primary' : 'light'}
            className={classNames('px-4 ms-2', {
              disabled: !canNextPage,
            })}
            onClick={onNextPage}
          >
            {PaginationMessages.NextPageLabel}
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default TablePagination
