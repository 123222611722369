import { Column } from 'react-table'
import format from 'date-fns/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEye, faShoePrints, faBan } from '@fortawesome/free-solid-svg-icons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { authTokenKey } from 'helpers/custom/tokens'
import { MemberViewModel } from 'services/api/endpoints/members'

export const PAGE_SIZE = 50
interface MembersState {
  searchTerm: string
  type: MembersFilterType | null
  pageNumber: number
}

export const initialMembersState: MembersState = {
  searchTerm: '',
  type: null,
  pageNumber: 0,
}

export enum MembersFilterType {
  TYPE1 = 'Type 1',
  TYPE2 = 'Type 2',
}

export type MembersAction =
  | {
      type: 'setSearchTerm'
      payload: string
    }
  | {
      type: 'setType'
      payload: MembersFilterType
    }
  | {
      type: 'nextPage'
    }
  | {
      type: 'previousPage'
    }

export const membersReducer = (state: MembersState, action: MembersAction): MembersState => {
  switch (action.type) {
    case 'setSearchTerm':
      return {
        ...state,
        searchTerm: action.payload,
        pageNumber: initialMembersState.pageNumber,
      }
    case 'setType':
      return {
        ...state,
        type: action.payload,
        pageNumber: initialMembersState.pageNumber,
      }
    case 'nextPage':
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
      }
    case 'previousPage':
      return {
        ...state,
        pageNumber: state.pageNumber - 1,
      }
    default:
      return initialMembersState
  }
}

export enum MembersMessages {
  PageTitle = 'Members',
  AllTypesLabel = 'All types',
  StatusColumnTitle = 'Active',
  SelectAriaLabel = 'Select type filter',
}

export const dupacoShineUrl = process.env.REACT_APP_DUPACO_SHINE_URL as string

const handleBecomeMember = (memberId: string) => {
  const authToken = localStorage.getItem(authTokenKey)
  window.open(`${dupacoShineUrl}/#token=${authToken ?? ''}/id=${memberId ?? ''}`, '_blank')?.focus()
}

export const tableColumns = [
  {
    Header: 'View',
    accessor: 'view',
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Button variant="falcon-default" size="sm">
            <FontAwesomeIcon icon={faEye as IconProp} />
          </Button>
        </div>
      )
    },
  },
  {
    Header: 'Username',
    accessor: 'Username',
  },
  {
    Header: 'Number',
    accessor: 'Number',
  },
  {
    Header: 'Name',
    accessor: 'Name',
    Cell: ({ value }) => (
      <span className="d-block text-wrap" style={{ maxWidth: '170px' }} title={value}>
        {value}
      </span>
    ),
  },
  {
    Header: 'Created',
    accessor: 'CreatedDate',
    Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
  },
  {
    Header: 'Last Accessed',
    accessor: 'LastAccessedDate',
    Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
  },
  {
    Header: 'Become',
    accessor: 'CanBecome',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      const { Id } = row.original as MemberViewModel
      return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          {value ? (
            <OverlayTrigger trigger="hover" overlay={<Tooltip>Become {row.values.Name}</Tooltip>}>
              <Button variant="falcon-default" size="sm" onClick={() => handleBecomeMember(Id!)}>
                <FontAwesomeIcon icon={faShoePrints as IconProp} />
              </Button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger trigger="hover" overlay={<Tooltip>Not available</Tooltip>}>
              <Button variant="link" className="text-muted" size="sm">
                <FontAwesomeIcon icon={faBan as IconProp} />
              </Button>
            </OverlayTrigger>
          )}
        </div>
      )
    },
  },
] as Column[]
