import { FC } from 'react'
import classNames from 'classnames'
import { ISoftBadge } from 'types'

const SoftBadge: FC<ISoftBadge> = function SoftBadge({
  bg = 'primary',
  pill,
  children,
  className,
}) {
  return (
    <div
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill,
      })}
    >
      {children}
    </div>
  )
}

export default SoftBadge
