import { Column } from 'react-table'
import format from 'date-fns/format'
import ActionsDropDown from 'components/custom/ActionsDropdown/ActionsDropdown'
import { EmployeeRole } from 'services/api/endpoints/users'
import { appRoutes } from 'routes/routes'

export const PAGE_SIZE = 50
interface UsersState {
  searchTerm: string
  status: UsersStatusType
  pageNumber: number
}

export const initialUsersState: UsersState = {
  searchTerm: '',
  status: undefined,
  pageNumber: 0,
}

export type UsersStatusType = UsersStatus | undefined

export enum UsersStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
}

export enum UsersFilterStatus {
  INACTIVE,
  ACTIVE,
}

export const UsersFilterStatusLabel = {
  [UsersFilterStatus.ACTIVE]: 'Active',
  [UsersFilterStatus.INACTIVE]: 'Inactive',
}

export type UsersAction =
  | {
      type: 'setSearchTerm'
      payload: string
    }
  | {
      type: 'setStatus'
      payload: UsersStatusType
    }
  | {
      type: 'nextPage'
    }
  | {
      type: 'previousPage'
    }

export const usersReducer = (state: UsersState, action: UsersAction): UsersState => {
  switch (action.type) {
    case 'setSearchTerm':
      return {
        ...state,
        searchTerm: action.payload,
      }
    case 'setStatus':
      return {
        ...state,
        status: action.payload,
        pageNumber: 0,
      }
    case 'nextPage':
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
      }
    case 'previousPage':
      return {
        ...state,
        pageNumber: state.pageNumber - 1,
      }
    default:
      return initialUsersState
  }
}

export enum UsersMessages {
  PageTitle = 'Users',
  AllStatusesLabel = 'All statuses',
  ActiveOnlyStatus = 'Active only',
  InactiveOnlyStatus = 'Inactive only',
  StatusColumnTitle = 'Active',
  SelectAriaLabel = 'Select status filter',
  AddButtonLabel = 'Add',
}

export const tableColumns = [
  {
    Header: 'Username',
    accessor: 'Username',
  },
  {
    Header: 'Role(s)',
    accessor: 'Roles',
    Cell: ({ value }) => {
      const allRoles = value.map((role: EmployeeRole) => role.Name)
      const nToDisplay = 2
      return (
        <span title={allRoles.join(', ')}>
          {allRoles.slice(0, nToDisplay).join(', ')}
          {allRoles.length > nToDisplay ? ` and ${allRoles.length - nToDisplay} more` : ''}
        </span>
      )
    },
  },

  {
    Header: 'Name',
    accessor: 'Name',
  },
  {
    Header: 'Created Date',
    accessor: 'CreatedDate',
    minWidth: 110,
    Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
  },
  {
    Header: 'Last Accessed Date',
    accessor: 'LastAccessedDate',
    minWidth: 160,
    Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
  },
  {
    Header: 'Status',
    accessor: 'Status',
    width: 80,
    Cell: ({ value }) => (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <div
          className={`rounded-circle shadow-4-strong ${
            value === UsersFilterStatus.ACTIVE ? 'bg-success' : 'bg-danger'
          }`}
          title={
            value === UsersFilterStatus.ACTIVE
              ? UsersFilterStatusLabel[UsersFilterStatus.ACTIVE]
              : UsersFilterStatusLabel[UsersFilterStatus.INACTIVE]
          }
          style={{ width: 10, height: 10 }}
        />
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'Id',
    disableSortBy: true,
    width: 60,
    Cell: ({ value, navigate }: any) => (
      <ActionsDropDown
        onEdit={() => navigate(appRoutes.users.edit.replace(':id', value))}
        onDelete={() => console.log(`Delete ${value}`)}
      />
    ),
  },
] as Column[]
