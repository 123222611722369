import { Alert } from 'react-bootstrap'
import * as yup from 'yup'

export enum UserFormMessages {
  FirstNameLabel = 'First name',
  LastNameLabel = 'Last name',
  EmailLabel = 'Email',
  UsernameLabel = 'Username',
  PasswordLabel = 'Password',
  ConfirmPasswordLabel = 'Confirm password',
  RolesLabel = 'Roles',
  CreatedDateLabel = 'Created date',
  LastSigninDateLabel = 'Last Signin Date',
  SaveUserLabel = 'Save user',
  FailToLoadRolesLabel = 'Please, try again.',
  UserNotFoundLabel = 'User not found',
  UserNotFoundDescriptionLabel = "This user doesn't exist.",
  RoleErrorLabel = 'Fail to load roles',
}

export interface UsersFormProps {
  id?: string
}

export const initUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  confirmPassword: '',
  roles: [],
  status: 0,
}

export const getUserFormSchema = (isEditMode: boolean) =>
  yup.object().shape({
    firstName: yup.string().max(50, 'Too Long!').required('First name is required'),
    lastName: yup.string().max(50, 'Too Long!').required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    username: yup.string().required('Username is required').max(50),
    password: yup.string().when([], {
      is: () => isEditMode,
      then: yup.string().nullable().min(6, 'Password is too short - should be 6 chars minimum'),
      otherwise: yup
        .string()
        .required('Password is required')
        .min(6, 'Password is too short - should be 6 chars minimum'),
    }),
    confirmPassword: yup.string().when('password', {
      is: (val: string) => !val,
      then: yup.string().nullable(),
      otherwise: yup
        .string()
        .required('Confirm the password')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    }),
    roles: yup.array().required('Required').min(1, 'Select at least one role'),
  })

export function UserNotFound() {
  return (
    <Alert variant="danger">
      <Alert.Heading>{UserFormMessages.UserNotFoundLabel}</Alert.Heading>
      <p>{UserFormMessages.UserNotFoundDescriptionLabel}</p>
    </Alert>
  )
}

export function RoleError({ message }: { message: string }) {
  return (
    <Alert variant="danger">
      <Alert.Heading>{UserFormMessages.RoleErrorLabel}</Alert.Heading>
      <p>{message}</p>
    </Alert>
  )
}
