import UsersForm from 'components/custom/UserForm/UserForm'
import { Card, Container } from 'react-bootstrap'
import { UserAddMessages } from './Add.utils'

export default function UsersAdd() {
  return (
    <Card className="mb-3">
      <Card.Body className="p-0">
        <Container className="gx-0 p-3">
          <h2 className="fs-1 mb-3 text-nowrap py-2 py-xl-0">{UserAddMessages.PageTitle}</h2>
          <UsersForm />
        </Container>
      </Card.Body>
    </Card>
  )
}
