/* eslint-disable react/jsx-key */
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Column, useSortBy, useTable } from 'react-table'
import TablePagination, { TablePaginationProps } from './Pagination/Pagination'
import { LoadingPlaceholder, renderSort } from './Table.utils'

interface CustomTableProps {
  columns: Column[]
  data: any
  isLoading?: boolean
  paginationOptions?: TablePaginationProps
}

export default function CustomTable({
  columns,
  data,
  paginationOptions,
  isLoading = false,
}: CustomTableProps) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetSortBy: false,
    },
    useSortBy
  )

  const navigate = useNavigate()

  return (
    <div className="table-responsive fs--1">
      <Table striped bordered hover className="border-bottom" {...getTableProps()}>
        <thead className="bg-200 text-900">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <th
                    className="border-0 text-nowrap"
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(column.headerProps),
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render('Header')}
                    {renderSort(column)}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} key="table-body">
          {isLoading
            ? headerGroups.map((headerGroup, idx) => (
                <LoadingPlaceholder headerGroup={headerGroup} key={idx} />
              ))
            : rows.map((row) => {
                prepareRow(row)
                return (
                  <tr className="border-200" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} style={{ verticalAlign: 'middle' }}>
                          {cell.render('Cell', { navigate })}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
        </tbody>
      </Table>

      {paginationOptions && (
        <TablePagination
          isLoading={isLoading}
          onPreviousPage={paginationOptions.onPreviousPage}
          onNextPage={paginationOptions.onNextPage}
          pageIndex={paginationOptions.pageIndex}
          numberOfPages={paginationOptions.numberOfPages}
          noDataToDisplay={!data.length}
        />
      )}
    </div>
  )
}
