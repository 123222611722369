import React, { FC, useContext } from 'react'
import classNames from 'classnames'
import AppContext from 'context/Context'
import { Nav } from 'react-bootstrap'
import { INavbarNavLink } from 'types'

const NavbarNavLink: FC<INavbarNavLink> = function NavbarNavLink({ title, route }) {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext)

  const handleClick = (): void => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed)
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu)
    }
  }
  return (
    <Nav.Link
      as={title ? 'p' : 'a'} // Need to Implement Link here
      className={classNames('fw-medium', {
        'text-500': !route?.active,
        'text-700 mb-0 fw-bold': title,
        'py-1': !title,
        'link-600': !title && route?.active,
      })}
      href={route?.to as string}
      onClick={handleClick}
    >
      {title || route!.name}
    </Nav.Link>
  )
}

export default NavbarNavLink
