import axios, { AxiosResponse } from 'axios'
import { userAuth } from 'services/store/slices'
import { dupacoManagementApi } from '..'
import { authTokenKey } from 'helpers/custom/tokens'
import { AppDispatch } from 'services/store/store'

function addUnauthorizedResponseInterceptor(dispatch: AppDispatch) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response as AxiosResponse<unknown, unknown>
      const UnauthorizedStatus = 401
      if (status === UnauthorizedStatus) {
        localStorage.removeItem(authTokenKey)
        dispatch(userAuth({ isAuth: false }))
        dispatch(dupacoManagementApi.util.resetApiState())
      }

      return Promise.reject(error)
    }
  )
}

export default addUnauthorizedResponseInterceptor
