/* eslint-disable react/jsx-key */
import SearchBar from 'components/custom/Table/SearchBar/SearchBar'
import CustomTable from 'components/custom/Table/Table'
import { useCallback, useReducer, useEffect, useMemo } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import {
  initialUsersState,
  PAGE_SIZE,
  tableColumns,
  UsersMessages,
  usersReducer,
  UsersStatus,
} from './Users.utils'
import debounce from 'lodash/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from 'routes/routes'
import { useEmployeeGetEmployeesQuery } from 'services/api/endpoints/users'

export default function Users() {
  const navigate = useNavigate()

  const [filterState, usersDispatch] = useReducer(usersReducer, initialUsersState)

  const { data, isFetching: isFetchingEmployees } = useEmployeeGetEmployeesQuery({
    searchTerm: filterState.searchTerm,
    status: filterState.status,
    pageSize: PAGE_SIZE,
    pageNumber: filterState.pageNumber,
  })

  const tableData = useMemo(() => data?.Employees ?? [], [data])
  const columns = useMemo(() => tableColumns, [])

  const handleChange = (value: string) => {
    usersDispatch({ type: 'setSearchTerm', payload: value })
  }

  const handleChangeStatus = (value: UsersStatus) => {
    usersDispatch({ type: 'setStatus', payload: value || undefined })
  }

  const debouncedSearch = useCallback(debounce(handleChange, 500), [])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [])

  return (
    <Card className="mb-3">
      <Card.Body className="p-0">
        <Container className="gx-0 p-3">
          <Row className="d-flex justify-content-between">
            <Col xs={4} className="d-flex align-items-center pe-0">
              <h2 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{UsersMessages.PageTitle}</h2>
              <Button
                size="sm"
                className="btn-falcon-default ms-3"
                onClick={() => navigate(appRoutes.users.add)}
              >
                <FontAwesomeIcon icon="plus" className="me-1" />
                {UsersMessages.AddButtonLabel}
              </Button>
            </Col>
            <Col>
              <Row className="d-flex justify-content-end g-2">
                <Col xs={4}>
                  <SearchBar search={filterState.searchTerm} setSearch={debouncedSearch} />
                </Col>
                <Col xs={4}>
                  <Form.Select
                    aria-label={UsersMessages.SelectAriaLabel}
                    onChange={(e) => {
                      handleChangeStatus(e.target.value as UsersStatus)
                    }}
                    size="sm"
                  >
                    <option value={''}>{UsersMessages.AllStatusesLabel}</option>
                    <option value={UsersStatus.ACTIVE}>{UsersMessages.ActiveOnlyStatus}</option>
                    <option value={UsersStatus.INACTIVE}>{UsersMessages.InactiveOnlyStatus}</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <CustomTable
          isLoading={isFetchingEmployees}
          data={tableData}
          columns={columns}
          paginationOptions={{
            onNextPage: () => usersDispatch({ type: 'nextPage' }),
            onPreviousPage: () => usersDispatch({ type: 'previousPage' }),
            pageIndex: filterState.pageNumber,
            numberOfPages: data?.NumberOfPages ?? 0,
          }}
        />
      </Card.Body>
    </Card>
  )
}
