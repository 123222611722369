import { userApi as api } from './users'

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    memberSearchMembers: build.query<MemberSearchMembersApiResponse, MemberSearchMembersApiArg>({
      query: (queryArg) => ({
        url: `/Manager/v1/Member/SearchMembers`,
        params: {
          SearchTerm: queryArg.searchTerm,
          PageNumber: queryArg.pageNumber,
          PageSize: queryArg.pageSize,
        },
      }),
    }),
    memberGetMember: build.query<MemberGetMemberApiResponse, MemberGetMemberApiArg>({
      query: (queryArg) => ({ url: `/Manager/v1/Member/GetMember`, params: { id: queryArg.id } }),
    }),
    memberBecomeMember: build.mutation<MemberBecomeMemberApiResponse, MemberBecomeMemberApiArg>({
      query: (queryArg) => ({
        url: `/Manager/v1/Member/BecomeMember`,
        method: 'POST',
        params: { id: queryArg.id },
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as memberApi }
export type MemberSearchMembersApiResponse = /** status 200 Status Code 200 - success

             */ SearchMembersResponseModel
export type MemberSearchMembersApiArg = {
  searchTerm: string
  pageNumber?: number
  pageSize?: number
}
export type MemberGetMemberApiResponse = /** status 200 Status Code 200 - success

             */ MemberDetailModel
export type MemberGetMemberApiArg = {
  id: string
}
export type MemberBecomeMemberApiResponse = /** status 200 Status Code 200 - success

             */ undefined
export type MemberBecomeMemberApiArg = {
  /** Member Id */
  id: string
}
export type MemberViewModel = {
  Id?: string
  Username?: string
  Number?: string
  Name?: string
  CreatedDate: string
  LastAccessedDate?: string
  CanBecome: boolean
}
export type SearchMembersResponseModel = {
  Members?: MemberViewModel[]
  NumberOfPages: number
}
export type ValueProviderResult = {
  AttemptedValue?: string
  Culture?: string
  RawValue?: any
}
export type Exception = {
  Message?: string
  InnerException?: Exception
  StackTrace?: string
  Source?: string
}
export type ModelError = {
  Exception?: Exception
  ErrorMessage?: string
}
export type ModelErrorCollection = ModelError[]
export type ModelState = {
  Value?: ValueProviderResult
  Errors?: ModelErrorCollection
}
export type ModelStateDictionary = {
  Count: number
  IsReadOnly: boolean
  IsValid: boolean
  Keys?: string[]
  Values?: ModelState[]
  Item?: ModelState
}
export type MemberDetailModel = {
  Id?: string
  Username?: string
  Number?: string
  Name?: string
  ReferenceID?: string
  PrimaryEmail?: string
  CreatedDate?: string
  UpdatedAt?: string
  LastAccessedDate?: string
  SuccessfulLoginCount?: string
  FailedLoginCount?: string
  SubUsers?: MemberDetailModel[]
  EnCryptParentModelID?: string
  ParentUserModel?: MemberDetailModel
}
export const {
  useMemberSearchMembersQuery,
  useMemberGetMemberQuery,
  useMemberBecomeMemberMutation,
} = injectedRtkApi
